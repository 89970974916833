/**
 * Body CSS
 */
html,
body {
  height: 100%;
}

html,
body,
input,
textarea,
button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

a {
  text-decoration: none;
}


/**
 * Header CSS
 */
header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

header ul.mui-list--inline {
  margin-bottom: 0;
}

header a {
  color: white;
}

header table {
  width: 100%;
}


/**
 * Content CSS
 */
#content-wrapper {
  min-height: 100%;
}
